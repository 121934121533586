
.app {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  overflow: hidden;
}


input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.4);
}

@media screen and (max-width: 500px) {
  .app {
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
  }

}

.dateTimeContainer > div > div, .dateTimeContainer > div > div > div > div{
  flex-wrap: wrap !important;
}
.no-chat-data-div {
  height: 100%;
  line-height: 400px;
  text-align: center;
  background: #fafafa;
  border-radius: 2%;
}
a {
  text-decoration: none !important;
}
.rs-picker-menu .rs-picker-toolbar-ranges{
  max-width: 450px !important;
}
.rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-bottom-start {
  margin-left: -128px !important;
}
.rs-stack-item {
  text-align: center;
}
.rs-picker.rs-picker-daterange.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-has-value.rs-picker-cleanable {
  text-align: center;
}
input.rs-picker-toggle-textbox {
  text-align: center;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #000 !important;
}

.profile-dropdown-container {  
  position: absolute;
  right: 42px;
  top: 56px;
}
.profile-dropdown-container > ul > li {  
  display: block;
  padding: 5px 10px;
}
.profile-dropdown-container > ul > li:hover {  
  background: #e7e7e7;
}
.profile-dropdown-container > ul {  
  background: #ffffff;
  min-width: 106px;
  /* padding: 10px; */
  border-radius: 4px;
  border: 1px solid #d9d9d9;

}

tr.approval-tr > td {
  text-align: center;
  padding: 10px;
}
.ref-name-value {
  width: 100%;
  display: flex;
}

.ref-name {
  font-weight: 600;
  margin-right: 1em;
  font-size: 1em;
  min-width: 11em;
}

.ref-value {
  display: contents;
}

.ref-offer {
  margin-bottom: 2em;
}
